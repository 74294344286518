<template>
    <div class='account_manage'>
        <!-- 标题 -->
        <div class="vue_title">
            <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
            <p>活动课程</p>
        </div>
        <div class="account_main">
            <!--  -->
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addEditBtn()" size="small" icon="el-icon-plus">新增</el-button>
                    <el-input v-model="title" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="请输入标题"></el-input>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="id" align="center" label="ID" width="100"></el-table-column>
                    <el-table-column prop="catalogue_id" label="课程目录ID" width="110"></el-table-column>
                    <el-table-column prop="title" label="标题" width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column label="课程视频" width="90">
                        <template slot-scope="scope">
                            <el-image style="width:55px; height: auto;cursor: pointer;" :src="scope.row.video_cover" @click="look(scope.row.video_link)">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="学习重点" width="200">
                        <template slot-scope="scope">
                            <el-tooltip placement="top">
                                <div slot="content" class="scope_tooltip">{{ scope.row.cont }}</div>
                                <div class="scope_txt one-txt-cut">{{ scope.row.cont }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="number" label="学习人数"></el-table-column>
                    <el-table-column prop="duration" label="时长(秒)"></el-table-column>
                    <el-table-column prop="sort" label="排序">
                        <template slot-scope="scope">
                            <div class="scope_sort">
                                <div class="input" v-if="scope.row.show">
                                    <el-input v-model="inputNum" @keyup.enter.native="$event.target.blur()" @blur="inputTopBlur" @input="limitSort" size="small" placeholder="请输入排序"></el-input>
                                </div>
                                <div class="num" v-else>{{ scope.row.sort }}</div>
                                <div class="icon" v-if="!scope.row.show" @click="sortBtn(scope.$index)">
                                    <i class="el-icon-edit"></i>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
                    <el-table-column prop="update_time" label="更新时间" width="180"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="addEditBtn(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="delBtn(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="accountDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除该视频后将不会展示在该列表，确定要删除该视频吗？</div>
            </div>
        </point-out>
        <video-preview ref="VideoPreview" :info="info"></video-preview>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import VideoPreview from '@/components/video_preview/index.vue'
export default {
    components: { PointOut, EmptyData, PagingPage, VideoPreview },
    props: {},
    data() {
        return {
            title: '',
            //
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            // 删除
            del_index: '',
            id: '',
            catalogue_id: '',
            srcList: [],
            info: {},
            // 
            inputNum:'',
            inputIndex:null,
        }
    },
    computed: {},
    watch: {},
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.search()
        }
    },
    mounted() { },
    methods: {
        search() {
            this.page = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        // 视频预览
        look(val) {
            this.info.video_url = val
            this.$refs.VideoPreview.openDialogBtn()
        },
        // 列表
        getList() {
            var that = this
            that.loading = true
            var params = {
                title: that.title,
                catalogue_id: that.id,
                page: that.page,
                limit: that.limit,
            }
            that.$activityApi.activityCatalogList(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    console.log(res)
                    that.list = res.result?.data
                    that.total = res.result?.total
                    that.list.forEach(ele => {
                        ele.cont = this.removeHTMLTag(ele.content)
                        ele.show = false
                    })
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 富文本去掉标签样式
        removeHTMLTag(str) {
            str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
            str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
            str = str.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
            str = str.replace(/&nbsp;/gi, ""); //去掉&nbsp;
            return str;
        },
        // 活动课程
        openList() {
            this.$refs.list.open()
        },
        // 新增/编辑
        addEditBtn(id) {
            if (id) {
                this.$router.push({
                    name: 'activity_course_add',
                    query: {
                        id: id,
                        catalogue_id: this.id,
                    }
                })
            } else {
                this.$router.push({
                    name: 'activity_course_add',
                    query: {
                        catalogue_id: this.id,
                    }
                })
            }
        },
        // 删除
        delBtn(index) {
            this.del_index = index
            this.$refs.dialogTip.openDialogBtn()
        },
        closeListBtn() {
            var that = this
            if (that.del_index !== '') {
                that.$activityApi.activityCatalogDel(that.list[that.del_index].id).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.getList()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 编辑排序
        sortBtn(index){
            var arr = JSON.parse(JSON.stringify(this.list))
            arr.forEach((ite,idx)=>{
                if(index == idx){
                    this.inputIndex = index
                    ite.show = true
                    this.inputNum = ite.sort
                }else{
                    ite.show = false
                }
            })
            this.list = arr
        },
        limitSort(e) {
            this.inputNum = e.replace(/[^0-9.]/g, '')
        },
        inputTopBlur(){
            var that = this
            if(that.inputNum===''){
                that.$errMsg('请输入排序(排序从大到小)')
                return
            }
            var params = {
                sort: that.inputNum,
            }
            that.$activityApi.activityCatalogEdit(params, that.list[that.inputIndex].id).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.inputIndex = null
                    that.getList()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 新增/编辑=确定
        submitBtn() {
            this.getList()
        },
        back() {
            this.$router.back()
        },
    },
}
</script>

<style lang='scss' scoped>
.account_manage {
    width: 100%;
    .account_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
            .cond_right {
                .el-button {
                    padding: 8px 10px;
                }
            }
        }
    }
}
.imgbox {
    display: flex;
    flex-wrap: wrap;
    .img {
        margin-right: 6px;
        margin-top: 6px;
    }
}
.scope_sort{
    display: flex;
    align-items: center;
    .input{
        width: 100px;
    }
    .icon{
        cursor: pointer;
        padding-left: 10px;
    }
}
.scope_txt{
    cursor: pointer;
}
.scope_tooltip{
    max-width: 800px;
    line-height: 20px;
}
</style>
